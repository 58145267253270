@use "src/assets/styles/shared" as *;

.date-range-picker {
  justify-content: flex-start !important;

  &-wrapper {
    display: inline-block;

    &.is-full-width {
      display: block;
    }
  }
}

.react-datepicker__day--today {
  font-weight: normal;
}

.container {
  @include flex(row, center, space-between);
  height: 40px;
}
.start-date {
  @include flex(row, center);
  margin-right: 20px;
}
.end-date {
  @include flex(row, center);
}

.date-picker {
  & .react-datepicker__input-container {
    > input {
      padding: 14px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
    }
  }
}
