.notes {
  max-height: 400px;
  overflow: auto;
}

.note {
  border-bottom: 1px solid #eee;
  padding: 8px 4px;
  margin-bottom: 8px;
  &-info {
    display: flex;
  }
  &-author {
    font-weight: bold;
  }
  &-date {
    margin-left: 4px;
  }
  &-content {
  }
}
