.rma-item {
  &__qty {
  }
  &__price {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &__label {
      font-weight: bold;
      padding-left: 14px;
    }
    &__input {
      padding-left: 10px;
    }
  }
}
