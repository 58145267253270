@use "src/assets/styles/base";
@use "src/assets/styles/shared" as *;

.login {
  &-container {
    width: 50%;
    margin: auto;
    padding: 40px;
  }
}
.otpInputContainer {
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .otpInput {
    border: 1px solid #c9c9c9;
    width: 55px !important;
    height: 55px;
    border-radius: 16px;
    margin: 0 5px;
    outline: none;
    #screen.sm-md {
      height: 50px !important;
    }
    #screen.xs-md {
      width: 45px !important;
      margin: 0;
    }
    &:focus {
      border: 1px solid $secondaryColor;
    }
  }
}
.error-block {
  @include flex(row, center, center);
}
