.orders-filters-form {
  padding: 20px;

  &-period-buttons {
    display: inline-block;
    margin-left: 20px;
  }

  .selected-button {
    border: 2px solid #18bbed;
  }
}
