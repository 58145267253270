.mobile-table {
  &-element {
    margin-bottom: 10px;
  }

  &-p {
    font-size: 0.875rem;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: rgba(0, 0, 0, 0.87);
  }

  &-pagination {
    margin-top: -10px;
  }
}
