.table-action {
  position: relative;
  &-selection {
    cursor: pointer;
  }
  &-list {
    cursor: pointer;
    position: absolute;
    width: 100%;
    min-width: 100px;
    right: 0;
    z-index: 4;
  }
}
