@use "src/assets/styles/shared" as *;

.permission-container {
  @include flex(row, center, center);
  flex-direction: column;
  padding: 40px;
  & h1 {
    margin: 0;
  }
  & img {
    width: 200px;
    height: 200px;
  }
}
