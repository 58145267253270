@use "src/assets/styles/shared" as *;

.admin-layout {
  display: flex;

  &-drawer {
    position: relative;
    padding-top: 10px;
    flex-shrink: 0;
    z-index: 1;

    &-logo {
      padding: 0 14px;
      text-align: center;
      margin: 24px 0 16px 0;
      img {
        max-width: 40%;
        display: block;
      }
    }
  }

  &-container {
    display: flex;
    width: 100%;
  }

  &-content {
    flex-grow: 1;
    padding: 24px;

    @media screen and (max-width: 678px) {
      max-width: 95%;
      margin: auto;
      padding: 0;
    }

    &-container {
      flex: 1;
      max-width: calc(100% - 240px);
    }

    &-without-padding {
      padding: 0;
      margin-top: 0;
    }
  }
}
