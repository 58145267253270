.coupon-listing {
  &-search {
    margin-right: 2em;
    margin-bottom: 1em;
    display: flex;
    gap: 1em;
    &-input {
      height: 100%;
      width: 100% !important;
    }
  }
}
