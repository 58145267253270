.media-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'render render render thumbnail'
    'render render render thumbnail'
    'render render render thumbnail'
    'actions actions actions actions';
  column-gap: 10px;
  row-gap: 10px;
  height: 100%;
  padding: 12px;

  &-container {
    height: 100%;
  }

  &__render {
    grid-area: render;
  }
  &__carousel-thumbnails {
    grid-area: thumbnail;
    display: flex;
    flex-direction: column;
  }
  &__carousel-thumbnail {
    flex: 1;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__actions {
    grid-area: actions;
  }
}

.media-drawer {
  padding: 20px;
}
