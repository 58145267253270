@use "src/assets/styles/shared" as *;

.busy-holder {
  &.backdrop {
    background: rgba(0, 0, 0, 0.85);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }

  .busy {
    position: absolute;
    left: 0;
    top: calc(50% - 60px);
    z-index: 9999;
    right: 0;
    text-align: center;
  }
}
