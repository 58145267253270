@use "src/assets/styles/shared" as *;

.error-page {
  @include flex(row, center, center);
  flex-direction: column;
  padding: 40px;

  &__heading {
    margin: 0;
  }

  &__img {
    width: 200px;
    height: 200px;
  }
}
