.status-indicator {
  display: inline-flex;
  &.active {
    color: green;
    .status-indicator-dot {
      background-color: green;
    }
  }
  &.error {
    color: tomato;
    .status-indicator-dot {
      background-color: tomato;
    }
  }

  &-dot {
    display: block;
    height: 12px;
    width: 12px;
    margin: auto;
    border-radius: 50%;
    background-color: darkgrey;
  }

  &-label {
    padding-left: 8px;
    text-transform: capitalize;
  }
}
