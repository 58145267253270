.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  margin: 5px;
  &-sku {
    font-weight: bold;
    padding: 8px 8px 8px 14px;
  }
  &-qty {
    margin-left: 6px;
    border-radius: 5px;
    padding: 8px;
    background-color: rgb(242, 242, 242);
  }
}
