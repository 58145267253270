@use "src/assets/styles/shared" as *;

.modal {
  @include flex(row, center, center);

  &-container {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    width: 600px;
    max-height: 95vh;
    overflow: auto;
    &:focus {
      outline: none;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }
  }
}
