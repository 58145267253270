.campaign-filters {
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-start;

  &-search-input {
    width: 100%;
  }

  &-dropdown {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
  &-dropdown-mobile {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin: 1em 0;
  }
  &-wrapper {
    margin-bottom: 1em;
    padding: 2em 1em;
  }
}
