@use "src/assets/styles/shared" as *;

.discount {
  &-add {
    @include flex(row, center, center);
    margin-top: 30px;
  }
  &-country {
    width: 200px;
  }

  &-search {
    margin-right: 2em;
  }

  &-dropdown {
    display: flex;
    .discount-country {
      margin-right: 1em;
    }
  }
}
