.issue-status-button {
  &-warning {
    background-color: #fac814 !important;
  }
  &-error {
    background-color: #f34725 !important;
    color: #fff !important;
  }
  &-success {
    background-color: #66bc69 !important;
    color: #fff !important;
  }
}
.issue-listing {
  &-flex-display {
    display: flex;
  }
}

.action-flex {
  display: flex;
  align-items: center;
}

.content-container button + button {
  margin-top: 0 !important;
}
.action-button {
  width: 1em !important;
  font-size: 0.8em !important;
}

.chip-border-radius-remove {
  border-radius: 8px !important;
}
.issue-filter-tab {
  display: flex;
  gap: 2em;
}
