.rma-form {
  &__description {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &__product-listing {
    padding-bottom: 16px;
  }
  &__product {
    margin-bottom: 18px;
  }
}
