.inventory-table {
  &-row {
    display: flex;
    padding: 10px;

    &:nth-of-type(2n) {
      background-color: #f7f7f7;
    }
  }

  &__country {
  }
  &__status {
  }
  &__actions {
  }
}
