$drawerWidth: 300;
$headerHeight: 64;

$font18: 18px;
$font16: 16px;
$font14: 14px;

$lightBackground: #eeeeee;
$darkBackground: #222222;

$primaryColor: #ea3729;
$primaryColorLight: #ee5f53;
$primaryColorDark: #a3261c;

$secondaryColor: #f27221;
$secondaryColorLight: #ffa15b;
$secondaryColorDark: #b26023;

//padding and margin values
$spaceAmounts: (
  0,
  5,
  10,
  15,
  16,
  20,
  24,
  25,
  30,
  35,
  40,
  45,
  50,
  56,
  55,
  64,
  70,
  72,
  75,
  80
);
$sides: (top, bottom, left, right, all);
