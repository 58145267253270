@use "src/assets/styles/shared" as *;

.file-upload {
  width: 100%;
  &-container {
    @include flex(row, null, space-between);
  }
  &-radio {
    & .MuiFormGroup-root {
      @include flex(row, null, null);
    }
  }
}
.loader {
  width: 50%;
  @include flex(row, center, center);
}
