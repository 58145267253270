.subscription-listing {
  &-search {
    margin-right: 2em;
    margin-bottom: 1em;
  }

  &-divider-margin-bottom {
    margin-bottom: 1em !important;
  }
}

.load-more-btn {
  text-align: center;
  margin-top: 1em;
}

.btn-flex {
  display: flex;
  justify-content: center;
  gap: 1em;
}
