@use "src/assets/styles/shared" as *;

html,
body {
  height: 100%;
  width: 100%;
}

@each $space in $spaceAmounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m#{$space} {
        margin: #{$space}px !important;
      }

      .p#{$space} {
        padding: #{$space}px !important;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

.button-container {
  @include flex(row, center, flex-end);
}

.row {
  @include margin(
    20px !important,
    0px !important,
    20px !important,
    0px !important
  );
}

.primary-label {
  font-size: #{$font16}px;
}

.text-center {
  text-align: center;
}
