@use "src/assets/styles/shared" as *;

.add-banner {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  @include flex(column, center, center);

  &-section {
    padding: 10px;
    border: 1px dashed lightgray;
    border-radius: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
    width: 140px;
    text-align: center;
    font-size: $font14;

    & > p {
      margin: 5px;
    }
  }
}

.color-picker {
  @include flex(row, center, flex-start);
  & > p {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
