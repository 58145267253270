@use "function" as *;

//Flex mixin
@mixin flex(
  $direction: initial,
  $alignItems: initial,
  $justifyContent: initial
) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}
