@use "src/assets/styles/shared" as *;

.nav-bar {
  &-category {
    &-header {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 16px;
      }
    }
  }
}
