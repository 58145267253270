.text-input-standalone {
  position: relative;
  display: flex;
  &-input {
    padding-right: 100px;
    width: 100%;
  }
  &-buttons {
    position: absolute !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &-button {
    &.MuiButton-containedSizeSmall {
      padding: 0 2px;
    }
    &.MuiButton-contained:hover {
      box-shadow: none;
    }
  }
}
