@use "src/assets/styles/shared" as *;

.noData-container {
  width: 100%;
  color: #6b6b6b;
  @include flex(column, center, center);
  padding: 40px;
  & p {
    margin-top: 10px;
  }
}
