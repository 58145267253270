.sortable-table {
  &-row {
    background-color: #eee;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 12px;
    margin: 4px;
    cursor: move;
  }
}
