@use "src/assets/styles/shared" as *;

.toolbar-container {
  padding-left: 16px;
  padding-right: 8px;
  @include flex(row, center, space-between);
}

.toolbar-container--highlight {
  color: #f50057;
}
