@use "src/assets/styles/shared" as *;

.edit-sku {
  width: 100%;
  @include flex(column, center, center);

  &-form {
    width: 50%;
  }
  &-label {
    font-size: 16px;
    font-weight: 600;
  }
  &-id {
    margin-left: 5px;
  }
  &-list {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    list-style: none;
    padding: 6px;
    width: 100%;
    &-item {
      margin: 4px;
    }
  }
}
