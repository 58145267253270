@use "src/assets/styles/base";

.file-drop-zone {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.file-input {
  &-input {
    position: relative;
    min-height: 42px;
    border-width: 1px;
    border-style: solid;
    border-color: #b2b2b2;
    border-radius: 8px;
    background-color: #fff;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 16px;
    &__filenames {
      position: absolute;
      left: 5px;
      top: 4px;
    }
    &__label {
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 5px;
      padding: 4px 8px;
      background: #eee;
      font-size: 14px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.active {
  border-color: #2196f3;
}
.accept {
  border-color: #00e676;
}

.reject {
  border-color: #ff1744;
}

.file-preview {
  max-width: 400px;
  margin-bottom: 10px;
  & > img {
    width: 100%;
    height: 100%;
  }
}
