@use "src/assets/styles/shared" as *;

.order-section {
  padding: 20px;
  margin-bottom: 20px;

  &-divider {
    border-color: $lightBackground;
    border-style: solid;
  }
}
