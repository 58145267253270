.page-content-layout {
  &-header {
    display: flex;
    align-items: center;
    &-title {
      flex: 1;
    }
    &-buttons {
      text-align: right;
    }
    &-flex {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
}
