@use "src/assets/styles/shared" as *;

.order-page-layout {
  &-header {
    position: sticky;
    top: #{$headerHeight}px;
    z-index: 2;
  }
  &-content {
    padding: 0 14px;
    margin-bottom: 50px;
  }

  &-nav,
  &-info {
    position: relative;
    padding: 0 24px;
  }

  &-info {
    &-inner {
      display: flex;
      align-items: center;
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-right {
      text-align: right;
    }
    &-title {
      margin-right: 20px;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
    }
    &-status {
      background: rgb(230, 230, 230);
      border-radius: 5px;
      padding: 4px 8px;
      &.failed,
      &.incomplete,
      &.invalid,
      &.needs-review {
        background: rgb(255, 24, 24);
        color: #fff;
      }
      &.cancelled {
        background: rgb(192, 15, 15);
        color: #fff;
      }
      &.in-quarantine {
        background: rgb(245, 148, 22);
      }
      &.is-frontend-active,
      &.processed,
      &.processed-capture,
      &.approved,
      &.cleared,
      &.authorized {
        background: rgb(68, 168, 37);
        color: #fff;
      }
      &.is-ready,
      &.pending-processed {
        background: rgb(195, 255, 177);
      }
      &.rejected {
        background: rgb(27, 27, 27);
        color: #fff;
      }
      &.pending-rejection {
        background: rgb(124, 124, 124);
        color: #fff;
      }
    }
    &-buttons {
    }
  }
  &-nav {
    border-bottom: rgb(230, 230, 230) 1px solid;
    &-inner {
      display: flex;
      align-items: center;
    }
    &-left {
      flex: 1;
    }
    &-right {
      text-align: right;
    }
  }
  &-back-button {
  }
}
