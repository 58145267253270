.dashboard {
  &-card {
    height: 100%;
    &-heading {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
}
