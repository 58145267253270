.game-manuals {
  display: flex;
  flex-direction: column;
}

.game-manual-file {
  display: flex;
  background-color: #f9f9f9;

  &:nth-of-type(2n) {
    filter: brightness(0.97);
  }

  &__url {
    width: 70%;
  }
  &__lang {
    width: 30%;
  }
}

.add-row {
  background-color: #ededed;
  border: none;
  display: block;
  appearance: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: filter 300ms ease;
  width: 100%;

  &:hover {
    filter: brightness(0.98);
  }

  &--disabled {
    opacity: 0.8;
    cursor: default;
  }
}
