.campaign-test-link {
  svg {
    font-size: 14px;
    margin-left: 4px;
  }
}
.campaign-cell-text-short {
  display: block;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
