@use "src/assets/styles/shared" as *;

.add-sku {
  width: 100%;
  @include flex(column, center, center);

  &-form {
    width: 100%;
  }
}
