.order-property {
  &-label {
    font-weight: bold;
    margin-right: 4px;
  }
  &-value {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    ul {
      margin: 0;
      padding: 0;
    }
  }
}

.order-properties-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
  align-items: center;
}
