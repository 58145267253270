.short-link-table {
  &-url {
    height: 18px;
    width: 140px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.short-link-modal {
  &-delete-box {
    display: flex;
  }
}

.short-link-field {
  &-flex-display {
    display: flex;
    align-items: center;
  }
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1) !important;
  -webkit-tap-highlight-color: transparent;
}
