@use "src/assets/styles/shared" as *;

.content-feature {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  @include flex(column, center, center);

  &-section {
    padding: 10px;
    border: 1px dashed lightgray;
    border-radius: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
    width: 140px;
    text-align: center;
    font-size: $font14;

    & > p {
      margin: 5px;
    }
  }
}
